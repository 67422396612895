import { useState } from "react";
import { Tab, TabList, SelectTabEvent } from "@fluentui/react-components";

import styles from "./Dashboard.module.css";
import Modal from "./Modal";

const Dashboard = () => {
    const [selectedTab, setSelectdTab] = useState("1");
    const [isDocOpen, openDocument] = useState(0);
    const onTabSelect = (event: SelectTabEvent, data: any) => {
        console.log(`The ${data.value} tab was selected`);
        setSelectdTab(data.value);
    };
    const tagsData = [
        ["قانون", "إعلانات", "تراخيص", "بلدية", "وزير", "طلب ترخيص", "تصميم إعلان", "صيانة", "إزالة إعلان", "مخالفة"],
        ["إعلانات", "تراخيص", "صيانة", "معاهد", "مباني حكومية", "مطار", "بلدية", "تنفيذ", "جريدة رسمية"],
        ["قرار", "مجلس الوزراء", "هيئة السياحة", "إعلانات", "تنظيم", "مادة", "تنفيذ", "قانون", "جريدة رسمية"],
        ["قرار", "إيجار", "عقود", "مد", "أماكن", "سكنى", "تجديد", "مستأجر", "شركات", "محامين", "محاسبين", "عيادات", "تنفيذ", "جريدة رسمية"],
        ["قرار", "إيجار", "عقود", "مد", "أماكن", "سكنى", "تجديد", "مستأجر", "شركات", "محامين", "محاسبين", "عيادات", "تنفيذ", "جريدة رسمية"],
        ["قرار", "إيجار", "أماكن", "سكنى", "تجديد", "مستأجر", "شركات", "محامين", "محاسبين", "عيادات", "تنفيذ", "جريدة رسمية"]
    ];
    const titles = ["Law 2012", "Law 1999", "Cabinet Decision 2013", "Cabinet Decision 2017", "Cabinet Decision 2018", "Cabinet Decision 2020"];
    const summary = [
        "يتعلق قانون رقم 1 لسنة 2012 بتنظيم ومراقبة وضع الإعلانات في دولة قطر، وينص القانون على ضوابط وشروط لوضع الإعلانات وتراخيصها، حيث يجب الحصول على ترخيص من البلدية المختصة قبل وضع أي إعلان، مع الالتزام بتقديم طلب للحصول على الترخيص وتحديد محتوى الإعلان وطريقة تثبيته. يحظر القانون وضع الإعلانات في بعض الأماكن مثل دور العبادة والمنشآت التاريخية، ويشترط أن تلتزم الإعلانات باللغة العربية وتتجنب المواد التي تسيء للأديان أو تخالف النظام العام. ينص القانون أيضًا على إجراءات التظلم والمراجعة في حالة رفض طلب الترخيص.",
        "يتضمن قانون رقم 10 لسنة 1999 تعديلات على بعض أحكام قانون تنظيم ومراقبة وضع الإعلانات رقم 4 لسنة 1980، حيث يتعلق التعديل بتغيير بعض الأنواع من التراخيص للإعلانات ومناطق مباح لوضع الإعلانات. كما ينص القانون على منع مباشرة الإعلانات في بعض الأماكن مثل أماكن العبادة والمعاهد العلمية والمباني الحكومية وغيرها. يكلف القانون الجهات المختصة بتنفيذه ويُعمل به بعد ثلاثين يومًا من تاريخ نشره في الجريدة الرسمية.",
        "تتضمن المادتان من القرار رقم 16 لسنة 2013 إضافة هيئة السياحة إلى الجهات المستثناة من بعض أحكام قانون تنظيم ومراقبة وضع الإعلانات، وتنص المادة الأولى على أن الهيئة العامة للسياحة تضاف للجهات المسؤولة عن وضع الإعلانات في المنشآت الفندقية والسياحية وقاعات المعارض والمرافق ذات الصلة. وتتعلق المادة الثانية بتنفيذ هذا القرار من قبل الجهات المعنية ونشره في الجريدة الرسمية.",
        "يتضمن قرار مجلس الوزراء رقم 11 لسنة 2017 تمديد عقود إيجار الأماكن وأجزاء منها لأغراض غير سكنية لمدة سنة، مع إمكانية عدم التجديد إذا كان المستأجر شاغلًا، ويستثنى من هذا التمديد الأماكن التجارية ومكاتب المحامين والمحاسبين والعيادات البيطرية ومكاتب الخبراء. ويكلف القرار جميع الجهات المختصة بتنفيذه وينشر في الجريدة الرسمية ويبدأ سريانه من تاريخ 15 فبراير 2017",
        "يتضمن قرار مجلس الوزراء رقم 8 لسنة 2018 تمديد عقود إيجار الأماكن وأجزاء منها لأغراض غير سكنية لمدة سنتين، مع إمكانية عدم التجديد إذا كان المستأجر شاغلًا، ويستثنى من هذا التمديد الأماكن التجارية ومكاتب المحامين والمحاسبين والعيادات البيطرية ومكاتب الخبراء. ويكلف القرار جميع الجهات المختصة بتنفيذه وينشر في الجريدة الرسمية ويبدأ سريانه من تاريخ 15 فبراير 2018.",
        "يتضمن قرار مجلس الوزراء رقم 1 لسنة 2020 تمديد عقود إيجار الأماكن وأجزاء منها لأغراض غير سكنية لمدة سنة، مع إمكانية عدم التجديد إذا كان المستأجر شاغلًا، ويستثنى من هذا التمديد الأماكن التجارية ومكاتب المحامين والمحاسبين والعيادات البيطرية ومكاتب الخبراء. ويكلف القرار جميع الجهات المختصة بتنفيذه وينشر في الجريدة الرسمية ويبدأ سريانه من تاريخ 15 فبراير 2020."
    ];
    return (
        <div className={styles.container}>
            <h2 className="mb-4">Documents Analyzer</h2>
            <div className={styles.row}>
                {[...new Array(6)].map((a, i) => (
                    <div className={styles.col4}>
                        <div className={styles.card}>
                            <div className={styles.cardBody}>
                                <h3 onClick={() => openDocument(i + 1)}>{titles[i]}</h3>
                                <div className={styles.content}>
                                    <p className={styles.tags}>
                                        {tagsData[i].map(tag => (
                                            <span>{tag}</span>
                                        ))}
                                    </p>
                                    <p className={styles.summary}>{summary[i]}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            {isDocOpen !== 0 && <Modal closeDoc={() => openDocument(0)} tags={tagsData[isDocOpen]} summary={summary[isDocOpen]} doc={isDocOpen} />}
        </div>
    );
};

export default Dashboard;
