import { useState } from "react";

import styles from "./Dashboard.module.css";

import Law1 from "../../assets/Law_2012_1.pdf";
import Law2 from "../../assets/Law_1999_10.pdf";
import Cabinet1 from "../../assets/Cabinet Decision_2013_16.pdf";
import Cabinet2 from "../../assets/Cabinet Decision_2017_11 (1).pdf";
import Cabinet3 from "../../assets/Cabinet Decision_2018_8 (1).pdf";
import Cabinet4 from "../../assets/Cabinet Decision_2020_1 (1).pdf";

const Modal = ({ ...props }) => {
    // const [fileName, setFile] = useState(0);
    const files = [Law1, Law2, Cabinet1, Cabinet2, Cabinet3, Cabinet4];
    const titles = ["Law 2012", "Law 1999", "Cabinet Decision 2013", "Cabinet Decision 2017", "Cabinet Decision 2018", "Cabinet Decision 2020"];

    return (
        <div className={styles.modal}>
            <header>
                <h3>{titles[props.doc-1]}</h3>
                <button className={styles.closeBtn} onClick={props.closeDoc}>
                    X
                </button>
                <div className={styles.btnGroup}>
                    <button className={styles.DownloadBtn}>Download</button>
                </div>
            </header>
            <div className={styles.docContainer}>
                <div className={styles.row}>
                    <div className={styles.col6}>
                        <embed src={files[props.doc - 1]} width="100%" />
                    </div>
                    <div className={styles.col6}>
                        <p className={styles.tags}>
                            {[...props.tags].map(a => (
                                <span>{a}</span>
                            ))}
                        </p>
                        <p>{props.summary}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Modal;
